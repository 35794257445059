import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
const Home = () => import('@/pages/index/home.vue')
const About = () => import('@/pages/index/about.vue')
const Scenic = () => import('@/pages/index/scenic.vue')
// const Hardware = () => import('@/pages/index/hardware.vue')
const Xiuduyou = () => import('@/pages/index/xiuduyou.vue')
const Case = () => import('@/pages/index/case.vue')
const Contact = () => import('@/pages/index/contact.vue')
const Detail = () => import('@/pages/index/detail.vue')

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/scenic',
    name: 'Scenic',
    component: Scenic
  },
  {
    path: '/xiuduyou',
    name: 'Xiuduyou',
    component: Xiuduyou
  },
  {
    path: '/case',
    name: 'Case',
    component: Case
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/detail/:tableName/:id',
    name: 'Detail',
    component: Detail
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
