<template>
  <div class="top_wrap" :class="{ active: isScroll }">
    <div class="top">
      <a href="/"><img src="@/assets/img/logo.png" alt="" class="logo" /></a>

      <div class="nav">
        <router-link to="/">首页</router-link>
        <router-link to="/about">企业介绍</router-link>
        <router-link to="/scenic">智慧景区</router-link>
        <router-link to="/xiuduyou">休度游OTS</router-link>
        <router-link to="/case">历史案例</router-link>
        <router-link to="/contact">联系我们</router-link>
      </div>
    </div>
    <div class="right_fix">
      <div class="kefu">
        <img class="kefu_bg" src="@/assets/img/kefu.png" alt="" />
        <div v-if="$store.state.allData.home.length" class="links">
          <a :href="$store.state.allData.home[0].kefu1">在线咨询</a>
          <a :href="$store.state.allData.home[0].kefu2">售前客服</a>
        </div>
      </div>
      <div v-show="!showNavs" @click="showNavs = true" class="tip_title">
        <div class="blue">
          <span>导航到<br />其他页面</span>
        </div>
      </div>
      <div v-show="showNavs" class="right_nav_items">
        <div v-for="item in services" :key="item.id">
          <div @click="goService(item.id)" class="item zoom">
            <img v-if="item.jicon" :src="item.jicon[0].url" alt="" />
            <span
              class="title"
              :class="{
                red:
                  $route.path == `/detail/services/${item.id}` ||
                  ($route.path == `/xiuduyou` && item.id == 1),
              }"
              >{{ item.title }}</span
            >
          </div>
        </div>
      </div>
      <div @click="gotoTop" v-show="isScroll" class="gotop">
        <div class="top">TOP</div>
        <img class="icon" src="@/assets/img/icon_top.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "VHeader",
  data() {
    return {
      isScroll: false,
      services: this.$store.state.allData.services,
      showNavs: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.debounce(function () {
        console.log("fuck");
      }, 200);
      if (window.scrollY > 250) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
    gotoTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    goService(id) {
      this.$router.replace(
        {
          path: "/detail/services/" + id,
        },
        () => {
          this.$router.go(0);
        }
      );
    },
    debounce(fn, delay) {
      var timeoutID = null;
      return function () {
        clearTimeout(timeoutID);
        var args = arguments;
        var that = this;
        timeoutID = setTimeout(function () {
          fn.apply(that, args);
        }, delay);
      };
    },
  },
});
</script>

<style scoped lang="scss">
.top_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transition: all 0.5s ease;
  &.active {
    background: rgba($color: #000000, $alpha: 0.5);
    .top {
      .logo {
        width: 130px;
      }
      .nav {
        margin-top: 10px;
      }
    }
  }

  .top {
    width: 1200px;
    margin: 10px auto;

    .logo {
      width: 200px;
      transition: all 0.5s ease;
    }

    .nav {
      width: 800px;
      float: right;
      margin-top: 20px;
      transition: all 0.5s ease;

      a {
        margin-left: 50px;
        display: inline-block;
        color: #fff;
        text-decoration: none;

        &.router-link-exact-active {
          color: #1ea7f5;
        }
      }
    }
  }
}

.right_fix {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.kefu {
  .kefu_bg {
    width: 100%;
  }
  .links {
    position: absolute;
    top: 160px;
    left: 0;
    right: 0;
    margin: auto;
    a {
      color: #3a3a3a;
      text-decoration: none;
      display: block;
      text-align: center;
      line-height: 30px;
      :hover {
        font-weight: bold;
        transform: scale(1.5);
      }
    }
  }
}
.tip_title {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  font-weight: bold;
  color: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background: #fdfdfd;
  box-shadow: 0px 0px 24px 0px rgba(123, 179, 226, 0.3);
  cursor: pointer;

  .blue {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    display: inline-block;
    background-color: #c1d9f7;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      text-align: center;
      line-height: 1.4;
    }
  }
}
.right_nav_items {
  background: #fdfdfd;
  box-shadow: 0px 0px 24px 0px rgba(123, 179, 226, 0.3);
  border-radius: 0px 0px 106px 106px;
  width: 140px;
  margin-top: -10px;
  transition: all ease-in-out 0.3s;

  .item {
    padding: 10px;
    background: #e6f6fd;
    box-shadow: 0px 3px 4px 0px rgba(147, 147, 147, 0.35);
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 20px;
      margin-right: 10px;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #3a3a3a;
      line-height: 1.4;
    }
    .red {
      color: red;
    }
  }
}
.gotop {
  margin: 10px auto;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s ease;
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 24px 0px rgba(123, 179, 226, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .top {
    width: 96px;
    font-size: 20px;
    font-weight: bold;
    color: #3a3a3a;
    line-height: 1;
  }
  .icon {
    width: 25px;
  }
}

@media screen and (max-width: 1400px) {
  .right_fix {
    display: none;
  }
}
</style>