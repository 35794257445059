import Vue from "vue";
import App from "./App.vue";
import vueNcform from "@ncform/ncform";
import router from './router'
import store from "@/common/store";
import ScrollReveal from "scrollreveal";




import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import ncformStdComps from "@ncform/ncform-theme-elementui";
import fetch from "@/common/axios";
import DrawerForm from "@/components/drawer_form.vue";
import VHeader from "@/components/header.vue";
import VFooter from "@/components/footer.vue";
import VListForm from "@/components/list_form.vue";
import Editor from "@/components/ncform_editor.vue";
import dayjs from "dayjs";


Vue.use(Element);
Vue.use(vueNcform, { extComponents: { ...ncformStdComps, Editor } });
Vue.prototype.$fetch = fetch;
// @ts-ignore
window.$fetch = fetch;
Vue.config.productionTip = false;

Vue.component("drawer-form", DrawerForm);
Vue.component("v-header", VHeader);
Vue.component("v-footer", VFooter);
Vue.component("v-list-form", VListForm);

Vue.filter("formateDate", (value: string) => {
  return dayjs(value).format("YYYY-MM-DD HH:mm:ss")
})


Vue.prototype.$isDev = process.env.NODE_ENV === "development"
Vue.prototype.$store = store
Vue.prototype.$ScrollReveal = ScrollReveal

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
