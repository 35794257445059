<template>
  <div class="bottom_wrap">
    <div class="container">
      <div class="top">
        <div class="qrs">
          <div class="qr_item">
            <div class="qr_img">
              <img src="@/assets/img/qr1.png" alt="" />
              <div class="qr_text">扫描二维码</div>
            </div>
          </div>
          <div class="qr_item">
            <div class="qr_img">
              <img src="@/assets/img/qr2.png" alt="" />
              <div class="qr_text">关注我们</div>
            </div>
          </div>
        </div>
        <div class="vertical_navs">
          <router-link to="/about">企业介绍</router-link>
          <router-link to="/scenic">智慧景区</router-link>
          <router-link to="/xiuduyou">休度游OTS</router-link>
          <router-link to="/case">历史案例</router-link>
          <router-link to="/contact">联系我们</router-link>
        </div>
        <div class="contact_info">
          <div class="contact_info_title">联系方式</div>
          <div class="decoration_block"></div>
          <div class="contact_info_item">
            <i class="el-icon-location"></i>
            地址:{{ data.address }}
          </div>
          <div class="contact_info_item">
            <i class="el-icon-phone"></i> 电话：{{ data.telphone }}
          </div>
          <div class="contact_info_item">
            <i class="el-icon-message"></i> 电子邮箱：{{ data.email }}
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="company_name">休度（重庆）科技有限公司</div>
      <div class="copyright">
        <div class="copyright_title">
          <a target="_blank" href="http://beian.miit.gov.cn/">
            {{ data.beian1 }}</a
          >
        </div>
        <div class="copyright_title">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50011202501692"
            ><img
              src="http://www.beian.gov.cn/img/new/gongan.png"
              style="float: left"
            />

            {{ data.beian2 }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "VFooter",
  data() {
    return {
      data: this.$store.state.allData.home[0],
    };
  },
});
</script>

<style scoped lang="scss">
.bottom_wrap {
  margin-top: 80px;
  // min-height: 500px;
  background: url("../assets/img/footer_bg.png") center center no-repeat;
  color: #fff;
  .container {
    width: 1220px;
    margin: 0 auto;
    .top {
      display: flex;
      padding-top: 20px;
      .qrs {
        margin-top: -20px;
        flex-basis: 550px;
        display: flex;
        flex-wrap: wrap;
        .qr_item {
          text-align: center;
          width: 220px;
          .qr_text {
            font-size: 20px;
            margin-top: -20px;
          }
        }
      }
      .vertical_navs {
        flex-basis: 240px;
        a {
          display: block;
          color: #fff;
          text-decoration: none;
          line-height: 42px;
          font-size: 18px;
        }
      }

      .contact_info {
        .contact_info_title {
          font-size: 24px;
        }
        .decoration_block {
          width: 20px;
          height: 4px;
          background-color: #ffffff;
          border-radius: 4px;
          margin-bottom: 40px;
        }
        .contact_info_item {
          margin-top: 24px;
          font-size: 16px;
          i {
            margin-right: 6px;
          }
        }
      }
    }
    .divider {
      width: 1220px;
      height: 1px;
      background-color: #ffffff;
      margin-top: 20px;
    }
    .company_name {
      font-size: 20px;
      text-align: center;
      margin-top: 20px;
    }
    .copyright {
      margin-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      font-size: 24px;

      .copyright_title {
        margin-bottom: 10px;
        font-size: 14px;
        text-align: center;
        a {
          display: inline-block;
          color: #fff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>