<template>
  <div id="app">
    <div v-if="loaded">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      loaded: false,
    };
  },
  created() {
    // this.$store.setAllData(JSON.parse(sessionStorage.getItem("allData") || {}));
  },
  mounted() {
    this.getAllDatas();
  },
  methods: {
    getAllDatas() {
      this.$fetch({ url: this.$isDev ? `/api/all` : "backend/db.json" }).then(
        (res) => {
          let { data } = res;
          this.loaded = true;

          Object.keys(data).forEach((key) => {
            const arr = data[key];
            if (arr.length && arr[0].order != null) {
              data[key] = data[key].sort((a, b) =>
                a.order < b.order ? 1 : -1
              );
            }
          });
          this.$store.setAllData(data);
          sessionStorage.setItem("allData", JSON.stringify(data));
        }
      );
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
.overflow {
  overflow: hidden;
}
.zoom {
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.1);
    z-index: 999 !important;
  }
}
.zoom105 {
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.05);
    z-index: 999 !important;
  }
}
.relative {
  position: relative;
}
#app {
  background-color: #fff;
}
</style>
