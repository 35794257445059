





































import Vue from "vue";

export default Vue.extend({
  props: {
    formSchemaSource: {
      type: Object,
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
    },
    id: {
      type: Number,
      default: undefined,
    },
    showDrawerOutside: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formSchema: {
        ...this.$props.formSchemaSource,
        value: {},
      },
      showForm: false,
    };
  },
  mounted() {
    console.log(this.$props.formSchemaSource);
    this.id && this.getData();
  },
  watch: {
    formSchema: {
      handler(val: any) {
        console.log(JSON.stringify(val.value));
        this.$emit("input", val.value);
      },
      deep: true,
    },
    showDrawerOutside: {
      handler(val) {
        this.showForm = val;
      },
      immediate: true,
    },
  },
  methods: {
    getData() {
      if (this.$isDev) {
        this.$fetch({ url: `${this.tableName}/${this.id}` }).then(
          (res: any) => {
            const data = res.data;

            this.formSchema.value = data;
            console.log("=======", data);
          }
        );
      } else {
        const data = this.$store.state.allData[this.tableName].find(
          (item: { id: number }) => item.id === this.id
        );

        this.formSchema.value = data;
      }
    },

    handleClose() {
      console.log("handleClose");
      this.showForm = false;
      this.$emit("hide");
    },
    cleanUploadUrl(properties: any, _value: any) {
      Object.keys(properties).forEach((key) => {
        if (properties[key].ui.widget == "upload") {
          _value[key] = _value[key].map((item: { name: any; url: any }) => ({
            name: item.name,
            url: item.url,
          }));
        }
      });
    },
    onSubmit() {
      this.$ncformValidate("form").then((data: any) => {
        if (data.result) {
          let _value = this.$data.formSchema.value;

          const { properties } = this.$props.formSchemaSource;
          this.cleanUploadUrl(properties, _value);

          this.$fetch({
            url: `${this.tableName}/${this.id || ""}`,
            method: this.id ? "PATCH" : "POST",
            data: _value,
          }).then((res: any) => {
            console.log(res.data);
            this.$message.success("保存成功");
            this.handleClose();
            // setTimeout(() => {
            //   window.location.reload(true);
            // }, 1000);
          });
        } else {
          this.$message.error("表单有必填项没完成!!请检查红色报错");
        }
      });
    },
  },
});
