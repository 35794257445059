
<template>
  <div>
    <!-- <vue-editor
      useCustomImageHandler
      @image-added="handleImageAdded"
      v-model="modelVal"
    ></vue-editor> -->
    <div id="wangEditor"></div>
  </div>
</template>


<script>
import ncformCommon from "@ncform/ncform-common";
import wangEditor from "wangeditor";
import axios from "axios";
import HtmlMenu from "./wangEditor.html";

export default {
  mixins: [ncformCommon.mixins.vue.controlMixin],

  i18nData: {
    // i18n
    en: {
      hi: "Hi <%= name %>",
    },
    zh_cn: {
      hi: "你好 <%= name %>",
    },
  },

  data() {
    return {
      defaultConfig: {
        // your config's default value ( Note: use mergeConfig to get config value )
        name: "daniel",
      },
      editor: null,
    };
  },
  mounted() {
    const editor = new wangEditor(`#wangEditor`);
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.modelVal = newHtml;
      console.log("editor changed: ", newHtml);
    };
    editor.config.height = 800;
    editor.menus.extend("htmlMenu", HtmlMenu);
    editor.config.menus = editor.config.menus.concat("htmlMenu");
    // 创建编辑器
    this.editor = editor;

    editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      var formData = new FormData();
      formData.append("file", resultFiles[0]);
      axios({
        url: "/upload",
        method: "POST",
        data: formData,
      })
        .then((result) => {
          let url = result.data.data.url;
          insertImgFn(url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    editor.create();
    editor.txt.html(this.modelVal);
    console.log(JSON.stringify(editor.config));
  },
};
</script>

