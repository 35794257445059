


























































import Vue from "vue";

export default Vue.extend({
  name: "VListForm",
  props: {
    list: {
      type: Array,
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
  },
  //   watch: {
  //     form: {
  //       handler(val: any) {
  //         if (!val.value) {
  //           return;
  //         }
  //         const { id } = val.value;
  //         this.tempList = this.tempList.map((item) => {
  //           if (item.id === id) {
  //             return val.value;
  //           }
  //           return item;
  //         });
  //       },
  //       deep: true,
  //     },
  //   },
  data() {
    return {
      id: 0,
      showDrawer: false,
      showDrawerOutside: false,
      form: {},
      formSchemaSource: {},
      tempList: [] as any[],
    };
  },
  mounted() {
    this.tempList = this.list;
    import(`@/common/form/${this.tableName}`).then(
      (module) => (this.formSchemaSource = module.default)
    );
  },
  methods: {
    handleClose() {
      this.showDrawer = false;
      this.showDrawerOutside = false;
    },
    onAdd() {
      this.showDrawerOutside = true;
      this.id = 0;
    },
    onEdit(id: number) {
      this.showDrawerOutside = true;
      this.id = id;
    },
    onDel(id: number) {
      this.$confirm("确认删除吗？")
        .then((_) => {
          this.$fetch({
            url: `${this.tableName}/${id}`,
            method: "DELETE",
          }).then((res: any) => {
            console.log(res.data);
            this.$message({ message: "删除成功", type: "success" });
            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          });
        })
        .catch((_) => {});
    },
  },
});
