export default {
    state: {
        allData: {
            home: [],
            about: [],
            scenic: [],
            xiuduyou: [],
            case: [],
            contact: [],

            categorys: [],
            services: [],
            certificates: [],
            partners: [],
            cases: [],
        }
    },
    setAllData(obj: any) {
        this.state.allData = obj;
    }
}