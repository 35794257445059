
import axios from 'axios'
const baseURL = "/"
export function isDev() {
    return process.env.NODE_ENV === "development" ? true : false
}

export default function fetch(options: {}) {

    return new Promise((resolve, reject) => {
        const instance = axios.create({ // instance创建一个axios实例，可以自定义配置，可在 axios文档中查看详情
            // 所有的请求都会带上这些配置，比如全局都要用的身份信息等。
            headers: {
                // 'Wechat-Authorization': Cookies('Wechat-Authorization')
                // 'Wechat-Authorization': '2e5b24b165c3480eabc4b4b752e31726'
            },
            baseURL: baseURL,
            timeout: 600 * 1000 // 30秒超时
        })
        instance(options)
            // let _this = this
            // then 请求成功之后进行什么操作
            .then(response => {


                if (response.data.code == 401) {
                    console.log("没有权限")

                } else {
                    resolve(response) // 把请求到的数据发到引用请求的地方
                }
            })
            .catch(error => {
                console.log({
                    message: '请求异常信息：' + error,
                    duration: 1500
                });
                // 异常502隐藏排队      
                reject(error)
            })
    })
}
